import React from 'react'
import { Link } from 'gatsby'

import { INTERNAL_URLS } from '../../../config/urls'

import { ServicesSectionImg } from '../shared/ServicesSectionImg'
import { ServicesLayout } from '../shared/ServicesLayout'
import { ServicesHeader } from '../shared/ServicesHeader'
import { ServicesChecklist } from '../shared/ServicesChecklist'
import { ServicesSectionText } from '../shared/ServicesSectionText'
import { ServicesListCard } from '../shared/ServicesListCard'
import { Card } from 'components/common/Card'
import { Stages } from 'components/common/Stages'
import HeartIcon from '../../svg/HeartIcon'

import PostnatalHeaderImg from 'components/images/postnatal-hero.jpg'
import InfantFeetFlowerImg from 'components/images/infant-feet-flower.png'

const POSTNATAL_STAGES = [
  { title: 'Childbirth', subtitle: 'Delivery' },
  { title: 'Postnatal Period', subtitle: '6 to 8 weeks after' },
]

// TODO: Update copy
const POSTNATAL_SERVICES_LIST = [
  'Safe pre-pregnancy assessment and counseling',
  <>
    Repeated early pregnancy loss{' '}
    <span className="text-xs italic">for recurrent miscarriage</span>
  </>,
  <>
    Early pregnancy genetic counseling{' '}
    <span className="text-xs italic">
      for identifying birth defects and conditions
    </span>
  </>,
  <>
    Low-risk pregnancy care with the expectation{' '}
    <span className="text-xs italic">of normal uncomplicated birth</span>
  </>,
  <>
    Pregnancy beyond 35 years of age{' '}
    <span className="text-xs italic">
      with knowledge of the potential risks
    </span>
  </>,
  <>
    High-risk pregnancy care{' '}
    <span className="text-xs italic">for multiple pregnancies</span>
  </>,
  <>
    Pregnancy complications from high blood pressure{' '}
    <span className="text-xs italic">from pre-eclampsia or diabetes</span>
  </>,
  <>
    Women with pre-existing medical problems{' '}
    <span className="text-xs italic">
      such as thyroid disease, autoimmune disorders, kidney disease or cardiac
      disease
    </span>
  </>,
  'Operative obstetrics & caesarean section',
]

export const PostnatalServices = () => {
  return (
    <ServicesLayout>
      <ServicesHeader
        title="Postnatal Care"
        description="We will work to make sure your own personal recovery goes smoothly, while your baby remains healthy and without complications."
        image={
          <ServicesSectionImg
            src={PostnatalHeaderImg}
            alt="Basket"
            direction="right"
            customStyles={{ container: 'mt-10 md-mt-0' }}
          />
        }
      />
      <section className="flex flex-wrap w-full justify-between mb-12 md-mb-12">
        <div className="mb-8 md-pr-16 w-full md-w-1/2">
          <ServicesSectionImg
            src={InfantFeetFlowerImg}
            alt="Infant's feet with a small flower between"
            direction="left"
            customStyles={{ container: 'hidden md-block w-full md-w-full mb-20' }}
          />
          <div className="mb-8 w-full">
            <Card component="article">
              <Card.Subheader>Tips For Healthy Recovery</Card.Subheader>
              <div className="md-flex md-space-x-8">
                <ServicesChecklist
                  className="md-w-1/2"
                  items={[
                    'Get plenty of rest',
                    'Stay hydrated',
                    'Eat well and a balanced diet',
                    'Talk with others',
                    'Take showers and keep clean',
                  ]}
                />
                <ServicesChecklist
                  className="md-w-1/2"
                  items={[
                    'Ask for help (meals, errands, etc)',
                    'Take light walks',
                    'Understand mood swings and depression can happen.  Don’t hesitate to reach out for help.'
                  ]}
                />
              </div>
            </Card>
          </div>
        </div>
        <div className="w-full md-w-1/2 md-pl-12">
          <ServicesSectionText title="Understanding the changes that come after childbirth." className="md-w-full mb-20">
            <div className="flex flex-col space-y-4">
              <p>
                After going through childbirth, it is essential that you recover
                both mentally and physically. As you are entering a chapter for
                your family and motherhood, there is often a period of adjustment
                needed.
              </p>
              <p>
                We have seen thousands of women go through this process and we
                hope to be there for you during this time as well to answer any
                questions you may have. Beyond learning to care for your baby
                though, it is vital to care for yourself in this period.
              </p>
              <p>
                Rest, recovery and proper nutrition can all be beneficial to your
                well-being, and there is no time more important to prioritize it
                than now. With us, we’ll be there to help guide you along the way.
              </p>
            </div>
          </ServicesSectionText>
          <Card component="article" className={'mb-8'}>
            <Card.Header>When is the Postnatal Period?</Card.Header>
            <Stages>
              {POSTNATAL_STAGES.map(({ title, subtitle }, idx) => {
                return (
                  <Stages.Stage
                    title={title}
                    subtitle={subtitle}
                    index={idx}
                    isLast={idx === POSTNATAL_STAGES.length - 1}
                    className="flex-1"
                  />
                )
              })}
            </Stages>
          </Card>
        </div>
      </section>

      <ServicesListCard
        className="bg-primary-300 text-white mb-12 md-mb-24"
        title="Our Postnatal & Postpartum Services"
        items={POSTNATAL_SERVICES_LIST}
        icon={<HeartIcon style={{ transform: 'translateY(1px)' }}/>}
      />
    </ServicesLayout>
  )
}
