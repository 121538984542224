import React from 'react'

import Layout from '../../components/layout'
import { PostnatalServices } from '../../components/Services/Postnatal'

const PostnatalPage = () => {
  return (
    <Layout seo={{ title: 'Postnatal Care' }}>
      <PostnatalServices />
    </Layout>
  )
}

export default PostnatalPage
