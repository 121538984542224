import React from 'react'

const HeartIcon = ({ className, ...props }) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`fill-current ${className}`} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-463.000000, -740.000000)" fill="#FFFFFF" fillRule="nonzero">
          <g transform="translate(463.000000, 740.254335)">
            <path d="M19.9669212,5.66264592 C19.6749812,2.44938362 17.4006193,0.118095275 14.5543012,0.118095275 C12.658038,0.118095275 10.9217839,1.13853876 9.94480451,2.77401773 C8.97667173,1.1173837 7.31157567,0.117710637 5.44569879,0.117710637 C2.59976537,0.117710637 0.325018751,2.44899898 0.033463469,5.66226128 C0.0103852145,5.80419255 -0.084235629,6.55115872 0.203473277,7.76930592 C0.618112583,9.52633037 1.57586015,11.1244995 2.97247918,12.3899571 L9.94018886,18.7130142 L17.0275208,12.3903418 C18.4241399,11.1244995 19.3818874,9.526715 19.7965267,7.76930592 C20.0842356,6.55154336 19.9896148,5.80457719 19.9669212,5.66264592 Z M19.0476374,7.59314191 C18.669154,9.19784988 17.7917957,10.6598573 16.5128757,11.8183857 L9.94480451,17.6783391 L3.48943208,11.8199242 C2.20820432,10.659088 1.33123065,9.19746524 0.952362636,7.59275727 C0.680039233,6.43999846 0.791968767,5.78880705 0.792353405,5.78457603 L0.798122969,5.74572764 C1.04813739,2.93056522 3.00248091,0.886985788 5.44569879,0.886985788 C7.24849511,0.886985788 8.83550974,1.994742 9.58863011,3.77753717 L9.94288132,4.61720099 L10.2971325,3.77753717 C11.0383291,2.02205127 12.7091948,0.887370425 14.5546858,0.887370425 C16.9975191,0.887370425 18.9522472,2.93094986 19.207262,5.78265285 C19.2080312,5.78880705 19.3199608,6.4403831 19.0476374,7.59314191 Z"></path>
            <path d="M6.15381657,2.45553782 C4.03292498,2.45553782 2.30744081,4.18102198 2.30744081,6.30191357 C2.30744081,6.51461815 2.47937381,6.68655115 2.69207839,6.68655115 C2.90478297,6.68655115 3.07671596,6.51461815 3.07671596,6.30191357 C3.07671596,4.60527723 4.45718022,3.22481297 6.15381657,3.22481297 C6.36652115,3.22481297 6.53845414,3.05287997 6.53845414,2.84017539 C6.53845414,2.62747082 6.36613651,2.45553782 6.15381657,2.45553782 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default HeartIcon
